<template>
    <div class="">
        <div style="">
            <div class="row margin-10-bottom" v-if="loaded">
                <div class="col-sm-4" v-if="otherUsersWhoseGoalsAreAvailable.length">
                    <select class="form-control" name="selectedGoalsFilter" id="selectedGoalsFilter"
                            v-model="selectedGoalsFilter"
                            style="margin-bottom: 5px"
                    >
                        <option :value="availableFilter.code" v-for="availableFilter in availableFilters">
                            {{ availableFilter.label }}
                        </option>
                    </select>
                </div>
                <div class="text-right"
                     :class="{'col-sm-8':otherUsersWhoseGoalsAreAvailable.length,'col-sm-12':!otherUsersWhoseGoalsAreAvailable.length}">
                    <strong><a href="javascript:;" @click.prevent.stop="onLinkGoalClick"
                               style="text-decoration: none; margin-right: 15px;"><i class="fa fa-link"></i> <span
                            style="text-decoration: underline">Link {{ 'Goal'|customTerm }}</span></a></strong>
                    <strong><a href="javascript:;" @click.prevent.stop="createGoal"
                               style="text-decoration: none;"><i class="fa fa-plus"></i> <span
                            style="text-decoration: underline">Create {{ 'Goal'|customTerm }}</span></a></strong>
                </div>
            </div>
            <div class="" v-if="loaded">
                <div v-for="linkGroup in groupedByCalendarOwnerLinks">
                    <div v-if="targetCalendarOwnerId!==linkGroup.calendarOwner.id" class="calendar-owner-header">
                        {{ 'Goals'|customTerm }} linked by <strong>{{ linkGroup.calendarOwner.name }}</strong>:
                    </div>
                    <event-detail-page-goals-goal
                            v-for="goalLink in linkGroup.links"
                            :calendar-owner-id="linkGroup.calendarOwner.id"
                            :calendarOwnerName="linkGroup.calendarOwner.name"
                            :event-id="eventId"
                            :goalTaskLayout="goalTaskLayout"
                            :goal="goalLink"
                            :key="getLinkKey(goalLink)"
                            :availableCompletionOptions="availableCompletionOptions"
                            @unlinkGoal="unlinkClicked(goalLink)"
                            @editLinkedTasks="editLinkedTasks(goalLink)"
                    ></event-detail-page-goals-goal>
                </div>
                <div class="alert alert-warning" v-if="links.length===0">
                    There are no Linked Goals yet.
                </div>
            </div>
            <div class="text-center" v-else>
                Loading...
            </div>
        </div>
    </div>
</template>

<script>
    import store from '../../store';
    import Vue from 'vue';
    import _ from 'lodash';

    import EventDetailPageGoalsGoal from './EventDetailPageGoalsGoal.vue';
    import ScreenWidthAwareMixin from "../../../../vue/mixins/ScreenWidthAwareMixin";
    import {EVENTS_BUS_EVENTS, eventsBus} from "./eventsBus";
    import EventDetailSubPageMixin from "./EventDetailSubPageMixin";
    import {CurrentUserClass} from "shared/CurrentUser";
    import {customTermFilter} from "../../../../vue/filters/CustomTerm";

    const ALL_GOALS = {code: 'all', label: `All ${customTermFilter('Goals')}`};
    const MY_GOALS = {code: 'my', label: `My ${customTermFilter('Goals')}`};

    export default {
        name: "EventDetailPageGoals",
        components: {EventDetailPageGoalsGoal},
        mixins: [ScreenWidthAwareMixin, EventDetailSubPageMixin],
        data() {
            return {
                links: [],
                loaded: false,
                selectedGoalsFilter: ALL_GOALS.code,
                availableCompletionOptions: [],
            };
        },
        props: {
            calendarOwnerId: String,
            calendarOwnerName: String,
            eventId: String,
            date: String,
        },
        store,
        computed: {
            canLinkOnlySUsGoals() {
                const CurrentUser = new CurrentUserClass();
                return !this.buddyMode && CurrentUser.is_buddy();
            },
            availableFilters() {
                const choices = [ALL_GOALS];
                for (const calendarOwner of this.otherUsersWhoseGoalsAreAvailable) {
                    choices.push({
                        code: calendarOwner.id,
                        label: `${calendarOwner.name}'s ${this.$customTerm('Goals')}`
                    });
                }
                return choices;
            },
            usersWhoseGoalsAreAvailable() {
                return _.sortBy(_.uniqBy(this.links.map(l => l.calendar_owner), co => co.id), co => co.name);
            },
            otherUsersWhoseGoalsAreAvailable() {
                return this.usersWhoseGoalsAreAvailable.filter(co => co.id !== this.targetCalendarOwnerId);
            },
            groupedByCalendarOwnerLinks() {
                let groups = [];
                const currentOwnersGoals = this.links.filter(l => l.calendar_owner.id === this.targetCalendarOwnerId);

                if (currentOwnersGoals.length) {
                    groups.push({
                        calendarOwner: currentOwnersGoals[0].calendar_owner,
                        links: currentOwnersGoals
                    });
                }
                for (const calendarOwner of this.otherUsersWhoseGoalsAreAvailable) {
                    groups.push({
                        calendarOwner,
                        links: this.links.filter(l => l.calendar_owner.id === calendarOwner.id)
                    });
                }
                if (this.selectedGoalsFilter !== ALL_GOALS.code) {
                    if (this.selectedGoalsFilter === MY_GOALS.code) {
                        groups = groups.filter(g => g.calendarOwner.id === this.targetCalendarOwnerId);
                    } else {
                        groups = groups.filter(g => (g.calendarOwner.id === parseInt(this.selectedGoalsFilter)));
                    }
                }
                return groups;
            },
            targetCalendarOwnerId() {
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                return this.calendarOwnerId ? parseInt(this.calendarOwnerId) : CurrentUser.id;
            },
            goalTaskLayout() {
                if (this.windowWidth >= 600) {
                    return 'desktop';
                } else {
                    return 'mobile';
                }
            },
        },
        methods: {
            getLinkKey(link) {
                return link.id + '|' + link.linked_tasks_id_list.join(':');
            },
            showGoalsSelector(allowCreateGoal = true, availableOwnersList = [], promiseToResolve) {
                const self = this;
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                const selectFactory = Vue.getAngularModule('selectFactory');
                const goalSettingsFactory = Vue.getAngularModule('goalSettingsFactory');
                const GoalResource = Vue.getAngularModule('GoalResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const $q = Vue.getAngularModule('$q');
                const goalIdDeferred = promiseToResolve || $q.defer();
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                let POPUP_CONFIG = {
                    header: `Select ${this.$customTerm('Goal')}`,
                    info: '',
                    button_yes: `Link ${this.$customTerm('Goal')}`,
                    button_no: 'Cancel',
                    empty: `You do not have any ${this.$customTerm('Goals')} to select`,
                    additional_buttons: []
                };
                if (allowCreateGoal) {
                    POPUP_CONFIG.additional_buttons.push(
                        {
                            text: `+ New ${this.$customTerm('Goal')}`,
                            classes: 'btn btn-mm btn-green',
                            result_code: 'create_goal'
                        }
                    );
                }

                const additionalFilters = [];

                additionalFilters.push({
                    name: 'show_archived_goals',
                    text: `Show archived ${this.$customTerm('Goals')}`,
                    inputType: 'switch',
                    filter: true,
                    value: false,
                    paramsWhenTrue: {
                        show_archived_goals: true
                    }
                });


                if (availableOwnersList.length > 1) {
                    const ownersFilter = {
                        type: 'select',
                        value: 'all',
                        slot: 'left-from-search',
                        long_text: true,
                        filter: true,
                        options: [
                            {value: 'all', name: 'All Goals'},
                            ...(() => {
                                const options = [];

                                if (!this.buddyMode && !this.canLinkOnlySUsGoals) {
                                    options.push({value: CurrentUser.id, name: `My ${this.$customTerm('Goals')}`});
                                }

                                for (const owner of availableOwnersList) {
                                    if (!this.buddyMode && (owner.id === CurrentUser.id)) {
                                    } else {
                                        options.push({
                                            value: owner.id,
                                            name: `${owner.name}'s ${this.$customTerm('Goals')}`
                                        });
                                    }
                                }
                                return options;
                            })()
                        ],
                        paramsWhenTrue: 'goal_owner'
                    };
                    additionalFilters.push(ownersFilter);
                }

                POPUP_CONFIG['additional_filters'] = additionalFilters;

                let goal_selector = selectFactory.endpoint_selector(
                    _.partial(CalendarEventGoalsResource.get_users_goals_for_link, this.targetCalendarOwnerId, this.eventId),
                    false, POPUP_CONFIG, false, false, true);

                goal_selector.then(result => {
                    if (result === 'create_goal') {
                        let auto_create = true;
                        goalSettingsFactory.create_goal(this.calendarOwnerName ? {
                            for_user: true,
                            username: this.calendarOwnerName,
                            hide_tasks: true,
                        } : {hide_tasks: true}, {allow_clone: true})
                            .then(goalData => {
                                if (goalData.goal_data.goal_to_copy) {
                                    auto_create = false;
                                }
                                $rootScope.show_dimmer();
                                return GoalResource.create_goal(goalData.goal_data);
                            }, reject => {
                                this.showGoalsSelector(allowCreateGoal, availableOwnersList, goalIdDeferred);
                                return new Promise(() => {
                                });
                            })
                            .then(resp => {
                                goalIdDeferred.resolve(resp.data.id);
                                notifications.success(`${this.$customTerm('Goal')} Created`);
                            }, err => {
                                $rootScope.hide_dimmer();
                                notifications.error(err || 'Error');
                                return new Promise(() => {
                                });
                            });
                    } else {
                        goalIdDeferred.resolve(result);
                        // goal_id itself then
                    }
                }, () => goalIdDeferred.reject());

                return goalIdDeferred.promise;
            },
            $_linkGoal(eventId, targetCalendarOwnerId, goalId, tasksIdList, allTasks) {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                $rootScope.show_dimmer();
                CalendarEventGoalsResource.link_goal(targetCalendarOwnerId, eventId, goalId, tasksIdList, allTasks)
                    .then(resp => {
                            this.links.unshift(resp.data);
                            this.$emit('updateGoalsCounter', this.links.length);
                            Vue.notifications.success('Goal Linked');
                        },
                        err => {
                            Vue.notifications.error(err || 'Error');
                        })
                    .finally($rootScope.hide_dimmer);
            },
            $_editGoalLink(eventId, targetCalendarOwnerId, link, tasksIdList, allTasks) {
                const goal = link.goal;
                const $rootScope = Vue.getAngularModule('$rootScope');
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                $rootScope.show_dimmer();
                CalendarEventGoalsResource.link_goal(targetCalendarOwnerId, eventId, goal.id, tasksIdList, allTasks)
                    .then(resp => {
                            Vue.set(this.links, this.links.indexOf(link), resp.data);
                            Vue.notifications.success('Link updated');
                        },
                        err => {
                            Vue.notifications.error(err || 'Error');
                        })
                    .finally($rootScope.hide_dimmer);
            },
            showTasksSelectorForLinkGoal(goal) {
                const goalId = goal.id;
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                const selectFactory = Vue.getAngularModule('selectFactory');
                let POPUP_CONFIG = {
                    header: `Select ${this.$customTerm('Tasks')}`,
                    info: `Select or de-
                    select ${this.$customTerm('Tasks')}
                    from list`,
                    button_yes: `Link ${this.$customTerm('Goal')}`,
                    button_no: 'Cancel',
                    empty: `You have no ${this.$customTerm('Tasks')} to select`,
                    additional_buttons: [],
                    select_all_html: `Select All<br>Including ${this.$customTerm('Tasks')} added later`,
                };
                let targetCalendarOwnerId = this.targetCalendarOwnerId;
                if (this.canLinkOnlySUsGoals) {
                    targetCalendarOwnerId = goal.owner.id;
                }
                let tasks_selector = selectFactory.endpoint_selector(
                    _.partial(CalendarEventGoalsResource.get_tasks_for_link_goal, this.eventId, targetCalendarOwnerId, goalId),
                    true, POPUP_CONFIG, true, true);
                tasks_selector.then(([checkedIdList, unchecked_id_list, allSelected]) => {
                    this.$_linkGoal(this.eventId, targetCalendarOwnerId, goalId, checkedIdList, allSelected);
                }, () => {
                });
            },
            showTasksSelectorForEditLink(link) {
                const goal = link.goal;
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                const selectFactory = Vue.getAngularModule('selectFactory');
                let POPUP_CONFIG = {
                    header: `Select ${this.$customTerm('Tasks')}`,
                    info: `Select or de-
                    select ${this.$customTerm('Tasks')}
                    from list`,
                    button_yes: 'Save',
                    button_no: 'Cancel',
                    empty: `You have no ${this.$customTerm('Tasks')} to select`,
                    additional_buttons: [],
                    select_all_html: `Select All<br>Including ${this.$customTerm('Tasks')} added later`,
                };
                let tasks_selector = selectFactory.endpoint_selector(
                    _.partial(CalendarEventGoalsResource.get_linked_tasks_for_edit, this.eventId, link.calendar_owner.id, goal.id),
                    true, POPUP_CONFIG, false, true);
                tasks_selector.then(([newCheckedIdList, newUncheckedIdList, allSelected]) => {
                    let checkedIdList = [];
                    if (!allSelected) {// add new to already checked and remove unchecked;
                        checkedIdList = checkedIdList.concat(link.linked_tasks_id_list);
                        checkedIdList = checkedIdList.concat(newCheckedIdList);
                        checkedIdList = checkedIdList.filter(id => newUncheckedIdList.indexOf(id) === -1);
                    }
                    this.$_editGoalLink(this.eventId, link.calendar_owner.id, link, checkedIdList, allSelected);
                }, () => {
                });
            },
            createGoal() {
                const goalSettingsFactory = Vue.getAngularModule('goalSettingsFactory');
                const CurrentUser = Vue.getAngularModule('CurrentUser');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const GoalResource = Vue.getAngularModule('GoalResource');
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                const notifications = Vue.getAngularModule('notifications');
                const popupConfig = {
                    allow_clone: true,
                    allow_create_for_user: CurrentUser.is_buddy(),
                    users_list: CurrentUser.dependents_profiles_ids_and_names_list.map(p => p.name),
                };
                const initialData = {hide_tasks: true};

                if (this.buddyMode) {
                    initialData.for_user = true;
                    initialData.username = this.calendarOwnerName;
                }
                goalSettingsFactory.create_goal(initialData, popupConfig)
                    .then(goalData => {
                        if (!goalData) {
                            return new Promise(() => {
                            });
                        }
                        $rootScope.show_dimmer();
                        return GoalResource.create_goal(goalData.goal_data);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        return resp.data.id;
                    })
                    .then(goalId => {
                        return CalendarEventGoalsResource.link_goal(this.targetCalendarOwnerId, this.eventId, goalId);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        notifications.success('Linked');
                        this.links.unshift(resp.data);
                        this.$emit('updateGoalsCounter', this.links.length);
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            getGoalOwnersForFilter() {
                return new Promise((res, rej) => {
                    const $rootScope = Vue.getAngularModule('$rootScope');
                    const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                    $rootScope.show_dimmer();
                    CalendarEventGoalsResource.get_goals_owners_for_filter(this.targetCalendarOwnerId, this.eventId)
                        .then(resp => {
                            res(resp.data);
                        }, err => {
                            Vue.notifications.error(err || 'Error');
                            rej();
                        })
                        .finally($rootScope.hide_dimmer);
                });

            },
            onLinkGoalClick() {
                const $q = Vue.getAngularModule('$q');
                this.getGoalOwnersForFilter()
                    .then(availableOwnersList => {
                        this.showGoalsSelector(false, availableOwnersList)
                            .then(goal => {
                                this.showTasksSelectorForLinkGoal(goal);
                            }, () => {
                            });
                    });
            },
            getLinkedGoals() {
                const $rootScope = Vue.getAngularModule('$rootScope');
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                $rootScope.show_dimmer();
                CalendarEventGoalsResource.get_linked_goals(this.targetCalendarOwnerId, this.eventId, this.event.start_date)
                    .then(resp => {
                        this.links = resp.data;
                        this.loaded = true;
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            unlinkGoal(link) {
                const goalId = link.goal.id;
                const $rootScope = Vue.getAngularModule('$rootScope');
                const notifications = Vue.getAngularModule('notifications');
                const CalendarEventGoalsResource = Vue.getAngularModule('CalendarEventGoalsResource');
                $rootScope.show_dimmer();
                CalendarEventGoalsResource.unlink_goal(link.calendar_owner.id, this.eventId, goalId)
                    .then(resp => {
                        this.links.splice(this.links.indexOf(link), 1);
                        this.$emit('updateGoalsCounter', this.links.length);
                        notifications.success('Removed');
                    }, err => {
                        notifications.error(err || 'Error');
                    })
                    .finally($rootScope.hide_dimmer);
            },
            editLinkedTasks(link) {
                this.showTasksSelectorForEditLink(link);
            },
            unlinkClicked(goal) {
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.show_popup(`Remove Linked ${this.$customTerm('Goal')}`, `Are you sure you want to remove the link to the ${this.$customTerm('Goal')} <strong>${goal.goal.name}</strong> from this Event? The ${this.$customTerm('Goal')} won’t be deleted.`, 'Remove', 'Cancel', 'btn-red')
                    .then(y => {
                        this.unlinkGoal(goal);
                    });
            },
            loadAvailableCompletionOptions() {
                const GoalResource = Vue.getAngularModule('GoalResource');
                GoalResource.get_available_assistance()
                    .then(({data}) => {
                        this.availableCompletionOptions = data;
                    }, err => {
                        Vue.notifications.error('Can\'t load available assistance');
                    });
            }
        },
        mounted() {
            this.loadAvailableCompletionOptions();
            this.getLinkedGoals();
            eventsBus.$on(EVENTS_BUS_EVENTS.LINK_GOAL, this.onLinkGoalClick);
        },
        beforeDestroy() {
            eventsBus.$off(EVENTS_BUS_EVENTS.LINK_GOAL, this.onLinkGoalClick);
        }
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";
  @import "../../styles/mixins";

  .event-goal {
    //@include wide_item_container();
  }

  .calendar-owner-header {
    font-size: $bigger-font-size;
    margin-bottom: 5px;
  }

</style>
